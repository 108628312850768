import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MEDIA from 'utils/helpers/media'

const Button = styled.input`
  position: ${(props) => (props.absolute ? 'absolute' : '')};
  bottom: 10px;
  right: 30px;
  border: 0;
  cursor: pointer;
  background: ${(props) => props.theme.colors.black};
  width: 60px;
  height: 30px;
  display: grid;
  place-items: center;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.msmall};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  transition: 0.1s all ease-in-out;
  &:hover {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.tsmall};
  width: 70px;
  height: 40px;
    `}
`

const InputButton = ({ clickHandler, absolute, isDisabled }) => (
  <Button
    disabled={isDisabled}
    absolute={absolute}
    value="Wyślij"
    type="submit"
    onClick={clickHandler}
  />
)

InputButton.propTypes = {
  clickHandler: PropTypes.func,
  absolute: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

InputButton.defaultProps = {
  absolute: false,
  isDisabled: false,
  clickHandler: () => {},
}

export default InputButton
